<template>
  <v-app dark>
    <router-view @theme-settings-click="handleThemeSettings"></router-view>
    <!-- setting drawer -->
    <v-navigation-drawer v-model="rightDrawer" class="setting-drawer" temporary right hide-overlay fixed>
      <theme-settings></theme-settings>
    </v-navigation-drawer>
    <!-- global snackbar -->
    <v-snackbar v-model="snackbar.show" :timeout="3000" bottom right :color="snackbar.color">
      {{ snackbar.text }}
      <v-btn dark text icon @click.native="snackbar.show = false">
        <v-icon>close</v-icon>
      </v-btn>
      <template #action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="$store.commit('HIDE_SNACKBAR')">
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import ThemeSettings from '@/components/ThemeSettings'
import { mapGetters } from 'vuex'

export default {
  components: {
    ThemeSettings,
  },
  data() {
    return {
      rightDrawer: false,
    }
  },
  computed: {
    ...mapGetters(['getSnackbar']),
    snackbar: {
      get() {
        return this.getSnackbar
      },
      set(val) {
        this.$store.commit('setSnackbar', val)
      },
    },
  },
  methods: {
    handleThemeSettings() {
      this.$vuetify.goTo(0)
      this.rightDrawer = !this.rightDrawer
    },
  },
}
</script>

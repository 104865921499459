import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '@/store'
Vue.use(VueI18n)

import en from '@/locale/en.json'
import ja from '@/locale/ja.json'

const i18n = new VueI18n({
  locale: store.getters.getLocale, // set locale
  messages: {
    en: en,
    ja: ja,
  },
})

export default i18n

const state = {
  login: {
    token: false,
    user_id: null,
    name: 'ゲスト',
    is_admin: false,
    user_class: 0,
    expire: 0,
  },
}

const getters = {
  getLogin: (state) => state.login,
  getLoginToken: (state) => state.login.token,
  getLoginUserID: (state) => state.login.user_id,
  isAdmin: (state) => state.login.is_admin,
  getUserClass: (state) => state.login.user_class,
}

const mutations = {
  saveLoginName: (state, name) => {
    state.login.name = name
  },
  saveLoginInfo: (state, login) => {
    state.login.token = login.token
    state.login.user_id = login.user_id
    state.login.name = login.name
    state.login.is_admin = login.is_admin
    state.login.user_class = login.user_class
    state.login.expire = Math.floor(1000 * login.expire)
  },
}

const actions = {
  setLoginName({ commit }, name) {
    commit('saveLoginName', name)
  },
  setLoginInfo({ commit }, login) {
    commit('saveLoginInfo', login)
  },
  unsetLoginInfo({ commit }) {
    commit('saveLoginInfo', {
      token: false,
      user_id: null,
      organization_id: null,
      name: 'ゲスト',
      is_admin: false,
      user_class: false,
      expire: 0,
    })
  },
}

export default {
  namespace: true,
  state,
  getters,
  actions,
  mutations,
}

import Vue from 'vue'
import Router from 'vue-router'
import { publicRoute, protectedRoute } from './config'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import store from '@/store/index.js'

const routes = publicRoute.concat(protectedRoute)

Vue.use(Router)
const router = new Router({
  mode: 'history',
  linkActiveClass: 'active',
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    // 前回の位置に戻る
    if (savedPosition) {
      return savedPosition
    }
    // URLにhashが含んでいたらハッシュの位置まで移動
    if (to.hash) {
      return { selector: to.hash }
    }
    // ページの先頭に移動
    return { x: 0, y: 0 }
  },
})
// router gards
router.beforeEach((to, from, next) => {
  NProgress.start()
  //auth route is authenticated
  if (
    (store.state.auth.login.token && store.state.auth.login.expire > new Date().getTime()) ||
    to.matched.every((page) => {
      for (const i in publicRoute) {
        if (publicRoute[i].path === page.path || publicRoute[i].redirect === page.path) {
          return true
        }
      }
      return false
    })
  ) {
    next()
  } else {
    next({ path: '/auth/login', query: { backuri: to.fullPath } })
  }
})

router.afterEach(() => {
  NProgress.done()
})

export default router

<template>
  <div class="themeSetting">
    <v-toolbar color="primary">
      <v-toolbar-title class="white--text">
        {{ $t('menu.theme_setting') }}
      </v-toolbar-title>
    </v-toolbar>
    <v-container>
      <v-row column>
        <v-col>
          <v-subheader class="px-1 my-2">
            {{ $t('page.theme_setting.theme_color_setting') }}
          </v-subheader>
          <v-divider />
          <div class="color-option">
            <v-item-group v-model="themeColor">
              <template v-for="item in themeColorOptions">
                <v-item :key="item" v-slot="{ active }" :value="item">
                  <v-avatar
                    :class="active ? 'active' : ''"
                    class="color-option__item"
                    :color="colors[item].base"
                    size="56"
                    tile
                    @click="handleChangeColor(item)"
                  >
                    <v-scroll-y-transition>
                      <v-icon v-if="active" color="white"> check </v-icon>
                    </v-scroll-y-transition>
                  </v-avatar>
                </v-item>
              </template>
            </v-item-group>
          </div>
          <div class="theme-options">
            <v-subheader class="px-1 my-2">
              {{ $t('page.theme_setting.background_color_setting') }}
            </v-subheader>
            <v-divider />
            <div class="my-3">
              <v-btn-toggle v-model="themeMode">
                <v-btn text value="dark">
                  {{ $t('page.theme_setting.dark') }}
                </v-btn>
                <v-btn text value="light">
                  {{ $t('page.theme_setting.light') }}
                </v-btn>
              </v-btn-toggle>
            </div>
          </div>
          <div class="theme-options">
            <v-subheader class="px-1 my-2"> 背景画像設定 </v-subheader>
            <v-divider />
            <div class="my-3">
              <v-select v-model="themeImage" :items="selectionItems.themeImage" />
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import colors from 'vuetify/es5/util/colors'

export default {
  data() {
    return {
      selectionItems: {
        themeImage: [
          { text: '無し', value: '' },
          { text: 'ハート五線譜', value: '/static/background/heart_note.png' },
          { text: 'ハートと花', value: '/static/background/heart_flower.jpg' },
          { text: '桜', value: '/static/background/sakura.jpg' },
          {
            text: 'クリスマスの夜空',
            value: '/static/background/christmas_star.jpg',
          },
          {
            text: 'ダイヤモンドグリッド',
            value: '/static/background/grid_diamond.jpg',
          },
          {
            text: 'カラフル水玉',
            value: '/static/background/colorful_polkadot.jpg',
          },
          {
            text: 'ブルーメモリー',
            value: '/static/background/blue_memory.jpg',
          },
          { text: '蝶模様', value: '/static/background/butterfly.jpg' },
          {
            text: '水色スレッド模様',
            value: '/static/background/watercolor_thread.jpg',
          },
          {
            text: '光のテクスチャー',
            value: '/static/background/light_texture.jpg',
          },
          {
            text: '六角形テクスチャー',
            value: '/static/background/hex_texture.jpg',
          },
          {
            text: '桟橋の風景1',
            value: '/static/background/pier_landscape1.jpg',
          },
          {
            text: '桟橋の風景2',
            value: '/static/background/pier_landscape2.jpg',
          },
          {
            text: 'ザルツギッターの湖',
            value: '/static/background/salzgitter_lake.jpg',
          },
          {
            text: '夕焼けの桟橋',
            value: '/static/background/sunset_pier.jpg',
          },
          { text: 'ツェルマット', value: '/static/background/zermatt.jpg' },
          { text: '木の風景', value: '/static/background/tree_landscape.jpg' },
          {
            text: '湿地の猫じゃらし',
            value: '/static/background/bristlegrass.jpg',
          },
          { text: 'ウェットオーク', value: '/static/background/wet_oak.jpg' },
          { text: '石積み', value: '/static/background/stone_wall.jpg' },
          { text: '赤レンガ', value: '/static/background/red_brick.jpg' },
          {
            text: 'フェンスグリッド',
            value: '/static/background/fence_grid.jpg',
          },
          {
            text: 'スカーフウール',
            value: '/static/background/scarf_wool.jpg',
          },
        ],
      },
      colors: colors,
    }
  },

  computed: {
    themeColorOptions() {
      return [
        'red',
        'pink',
        'purple',
        'deepPurple',
        'indigo',
        'blue',
        'lightBlue',
        'cyan',
        'teal',
        'green',
        'lightGreen',
        'lime',
        'yellow',
        'amber',
        'orange',
        'deepOrange',
        'brown',
        'blueGrey',
        'grey',
      ]
    },
    themeColor: {
      get() {
        return this.$store.getters.getThemeColor
      },
      set(val) {
        this.$vuetify.theme.themes.light.primary = colors[val].base
        this.$vuetify.theme.themes.dark.primary = colors[val].base
        this.$vuetify.theme.themes.light.accent = colors[val].lighten3
        this.$vuetify.theme.themes.dark.accent = colors[val].darken3
        this.$store.commit('setThemeColor', val)
      },
    },
    themeMode: {
      get() {
        return this.$store.getters.getThemeMode
      },
      set(val) {
        this.$vuetify.theme.dark = val === 'dark'
        this.$store.commit('setThemeMode', val)
      },
    },
    themeImage: {
      get() {
        return this.$store.getters.getThemeImage
      },
      set(val) {
        this.$store.commit('setThemeImage', val)
      },
    },
  },

  created() {
    this.themeColor = this.$store.getters.getThemeColor
    this.themeMode = this.$store.getters.getThemeMode
    this.themeImage = this.$store.getters.getThemeImage
  },

  methods: {
    handleChangeColor(option) {
      this.themeColor = option
    },
  },
}
</script>
<style lang="sass" scoped>
.color-option
  &__item
    display: inline-flex
    margin: 3px
    cursor: pointer
</style>
